import React, { useEffect, useState } from "react";
// eslint-disable-next-line no-unused-vars
import pJS from "particles.js";
import logo from "./logo.svg";
import { ReactComponent as Email } from "./media/images/email.svg";
import { ReactComponent as Facebook } from "./media/images/facebook.svg";
// import { ReactComponent as Github } from "./media/images/github.svg";
import { ReactComponent as Instagram } from "./media/images/instagram.svg";
// import { ReactComponent as Linkedin } from "./media/images/linkedin.svg";
// import { ReactComponent as Pinterest } from "./media/images/pinterest.svg";
// import { ReactComponent as Reddit } from "./media/images/reddit.svg";
// import { ReactComponent as Snapchat } from "./media/images/snapchat.svg";
// import { ReactComponent as SoundCloud } from "./media/images/soundcloud.svg";
// import { ReactComponent as Spotify } from "./media/images/spotify.svg";
import { ReactComponent as TikTok } from "./media/images/tiktok.svg";
// import { ReactComponent as Twitch } from "./media/images/twitch.svg";
import { ReactComponent as Twitter } from "./media/images/twitter.svg";
// import { ReactComponent as Whatsapp } from "./media/images/whatsapp.svg";
// import { ReactComponent as Youtube } from "./media/images/youtube.svg";
import { isMobile } from "react-device-detect";
import "./App.css";

function App() {
  const [offset, setOffset] = useState(0);
  const [offsetType, setOffsetType] = useState("px");
  const scroll = (p) => {
    const newOffset =
      offset + p.deltaY > window.innerHeight
        ? window.innerHeight
        : offset + p.deltaY < 0
        ? 0
        : offset + p.deltaY;
    setOffsetType("px");
    setOffset(newOffset);
  };
  useEffect(() => {
    window.particlesJS(
      "particles-js",

      {
        particles: {
          number: {
            value: 80,
            density: {
              enable: true,
              value_area: 800,
            },
          },
          color: {
            value: "#334b48",
          },
          shape: {
            type: "circle",
            stroke: {
              width: 0,
              color: "#334b48",
            },
            polygon: {
              nb_sides: 5,
            },
            image: {
              src: "img/github.svg",
              width: 100,
              height: 100,
            },
          },
          opacity: {
            value: 0.5,
            random: false,
            anim: {
              enable: false,
              speed: 1,
              opacity_min: 0.1,
              sync: false,
            },
          },
          size: {
            value: 5,
            random: true,
            anim: {
              enable: false,
              speed: 40,
              size_min: 0.1,
              sync: false,
            },
          },
          line_linked: {
            enable: true,
            distance: 150,
            color: "#334b48",
            opacity: 0.4,
            width: 1,
          },
          move: {
            enable: true,
            speed: 6,
            direction: "none",
            random: false,
            straight: false,
            out_mode: "out",
            attract: {
              enable: false,
              rotateX: 600,
              rotateY: 1200,
            },
          },
        },
        interactivity: {
          detect_on: "canvas",
          events: {
            onhover: {
              enable: true,
              mode: "grab",
            },
            onclick: {
              enable: !isMobile,
              mode: "push",
            },
            resize: true,
          },
          modes: {
            grab: {
              distance: 400,
              line_linked: {
                opacity: 1,
              },
            },
            bubble: {
              distance: 400,
              size: 40,
              duration: 2,
              opacity: 8,
              speed: 3,
            },
            repulse: {
              distance: 200,
            },
            push: {
              particles_nb: 4,
            },
            remove: {
              particles_nb: 2,
            },
          },
        },
        retina_detect: true,
        config_demo: {
          hide_card: false,
          background_color: "#dae5df",
          background_image: "",
          background_position: "50% 50%",
          background_repeat: "no-repeat",
          background_size: "cover",
        },
      }
    );
  }, []);
  return (
    <div className='App' onWheel={(p) => scroll(p)}>
      <div id='particles-js'>
        <canvas
          className='particles-js-canvas-el'
          width='1920'
          height='860'
          style={{ width: "100%", height: "100%" }}
        ></canvas>
      </div>
      <header
        className='App-header'
        style={{
          top: `calc(0vh - ${offset + offsetType})`,
        }}
      >
        <img src={logo} className='App-logo' alt='logo' />
        <h1>SHELFGROW</h1>
      </header>
      <header
        className='App-header'
        style={{
          top: `calc(100vh - ${offset + offsetType})`,
        }}
      >
        <div className='social-container'>
          <a href='mailto:info@shelfgrow.com' target='_blank' rel='noreferrer'>
            <Email width={null} height={null} className='social-icon' />
          </a>
          <a
            href='https://www.facebook.com/ShelfGrow'
            target='_blank'
            rel='noreferrer'
          >
            <Facebook width={null} height={null} className='social-icon' />
          </a>
          {/* <a
            href='https://github.com/shelfgrow'
            target='_blank'
            rel='noreferrer'
          >
            <Github width={null} height={null} className='social-icon' />
          </a> */}
          <a
            href='https://www.instagram.com/shelfgrowofficial/'
            target='_blank'
            rel='noreferrer'
          >
            <Instagram width={null} height={null} className='social-icon' />
          </a>
          {/* <a
            href='https://www.linkedin.com/company/shelfgrow/'
            target='_blank'
            rel='noreferrer'
          >
            <Linkedin width={null} height={null} className='social-icon' />
          </a> */}
          {/* <a
            href='https://za.pinterest.com/shelfgrow/'
            target='_blank'
            rel='noreferrer'
          >
            <Pinterest width={null} height={null} className='social-icon' />
          </a> */}
          {/* <a
            href='https://www.reddit.com/r/shelfgrow/'
            target='_blank'
            rel='noreferrer'
          >
            <Reddit width={null} height={null} className='social-icon' />
          </a> */}
          {/* <a
            href='https://www.snapchat.com/add/shelfgrow'
            target='_blank'
            rel='noreferrer'
          >
            <Snapchat width={null} height={null} className='social-icon' />
          </a> */}
          {/* <a
            href='https://soundcloud.com/shelfgrow'
            target='_blank'
            rel='noreferrer'
          >
            <SoundCloud width={null} height={null} className='social-icon' />
          </a> */}
          {/* <a
            href='https://open.spotify.com/user/313itzffswzqpltzu53ou6ythzc4'
            target='_blank'
            rel='noreferrer'
          >
            <Spotify width={null} height={null} className='social-icon' />
          </a> */}
          <a
            href='https://www.tiktok.com/@shelfgrow'
            target='_blank'
            rel='noreferrer'
          >
            <TikTok width={null} height={null} className='social-icon' />
          </a>
          {/* <a
            href='https://www.twitch.tv/shelfgrow'
            target='_blank'
            rel='noreferrer'
          >
            <Twitch width={null} height={null} className='social-icon' />
          </a> */}
          <a
            href='https://twitter.com/ShelfGrow'
            target='_blank'
            rel='noreferrer'
          >
            <Twitter width={null} height={null} className='social-icon' />
          </a>
          {/* <a href='tel:0763083673' target='_blank' rel='noreferrer'>
            <Whatsapp width={null} height={null} className='social-icon' />
          </a> */}
          {/* <a
            href='https://www.youtube.com/channel/shelfgrow'
            target='_blank'
            rel='noreferrer'
          >
            <Youtube width={null} height={null} className='social-icon' />
          </a> */}
        </div>
      </header>
      {/* <main className='App-main'>
        <span>⌂</span>
        <span>ⓘ</span>
        <span>✉</span>
      </main> */}
      <footer className='App-footer'>
        <small>
          &copy; Copyright 2022-{new Date().getFullYear()}, SHELFGROW. All
          Rights Reserved
        </small>
      </footer>
    </div>
  );
}

export default App;
